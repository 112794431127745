import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { usePlpRetentionStore } from '@finn/ua-vehicle';
import { pick } from 'lodash';
import { useRouter } from 'next/compat/router';
import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect } from 'react';

import { getLastDateOfWeekOfSubscriptionEnd } from '~/modules/recommendation/utils';

type Props = {
  data: any;
  hasExactNumberOfFilters: boolean;
};
export const useRecommendationTracker = ({
  data,
  hasExactNumberOfFilters,
}: Props) => {
  const lastOpenedSubscription = usePlpRetentionStore(
    (state) => state.lastOpenedSubscription
  );
  const endOfWeek = getLastDateOfWeekOfSubscriptionEnd(lastOpenedSubscription);
  const router = useRouter();
  const searchParams = useSearchParams();
  const availableFrom = (router?.query?.available_from ||
    searchParams?.get('available_from')) as string;
  const availableTo = (router?.query?.available_to ||
    searchParams?.get('available_to')) as string;
  const vehicles = data?.fleetData?.length ? data?.fleetData : [];
  const shouldTriggerShowedEvent =
    availableFrom &&
    availableTo &&
    availableTo === endOfWeek &&
    hasExactNumberOfFilters &&
    vehicles.length > 0;

  const lastSubscriptionTrackingData = pick(lastOpenedSubscription, [
    'id',
    'customer_id',
    'type',
    'term',
    'mileage_package',
    'is_prolongation',
    'amount',
    'car.external_product_id',
    'car.fuel_type',
  ]);

  useEffect(() => {
    if (shouldTriggerShowedEvent) {
      interactionTrackingEvent(TrackingEventName.CAR_RECOMMENDATIONS_SHOWN, {
        originalSubscription: lastSubscriptionTrackingData,
        originalVehicle: data?.original_vehicle,
        recommended_vehicles: data?.recommended_vehicles,
      });
    }
  }, [shouldTriggerShowedEvent]);

  const onRecommendationClick = useCallback(
    (carId: string) => {
      interactionTrackingEvent(TrackingEventName.CAR_RECOMMENDATIONS_CLICKED, {
        originalSubscription: lastSubscriptionTrackingData,
        vehicle: data?.recommended_vehicles?.find((car) => car.id === carId),
      });
    },
    [data?.recommended_vehicles]
  );

  return { onRecommendationClick };
};
