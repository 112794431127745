import { useIntl } from '@finn/ui-utils';
import { useCallback, useMemo } from 'react';

import {
  FilterKey,
  FiltersResponse,
  parseFiltersResponse,
} from '../../../core';
import { FiltersState, useFiltersResponse } from '../../../filters-management';
import { FilterData, FilterToggleData, TestProps } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { SingleSelectFilter } from '../atoms/SingleSelectFilter';

type Props = TestProps & {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: (filterObject: FiltersState) => void;
};

export const TermsFilter = ({
  selectedFilters,
  availableFilters,
  onOptionClick,
  testId,
}: Props) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked(FilterKey.TERMS);
  const filtersResponse = useFiltersResponse();

  const parsedFiltersResponse = parseFiltersResponse(
    FilterKey.TERMS,
    filtersResponse,
    i18n
  );

  const selectedValues = selectedFilters[FilterKey.TERMS];

  const availableFilterValues: FilterToggleData[] = parsedFiltersResponse.map(
    (configItem) => ({
      value: Number(configItem?.value) || configItem,
      label: configItem?.label ?? configItem,
      selected: selectedValues?.includes(
        Number(configItem?.value) || configItem
      ),
      disabled: !availableFilters[FilterKey.TERMS].includes(
        Number(configItem?.value) || configItem
      ),
    })
  );

  availableFilterValues.unshift({
    value: undefined,
    label: i18n.formatMessage('plp.all'),
    selected: !selectedValues,
    disabled: false,
  });

  const data = useMemo<FilterData>(
    () => ({
      filterKey: FilterKey.TERMS,
      selectedValues,
      availableFilterValues,
    }),
    [
      filtersResponse[FilterKey.TERMS],
      availableFilters[FilterKey.TERMS],
      selectedValues,
    ]
  );

  const handleTermsClick = useCallback(
    (value) => {
      value.terms = value.terms.map((val) => parseInt(val));
      onOptionClick(value);
    },
    [onOptionClick]
  );

  return (
    <SingleSelectFilter
      title={i18n.formatMessage('plp.terms')}
      selectedValue={selectedValues ? selectedValues[0] : undefined}
      data={data}
      onClick={handleTermsClick}
      onOpen={trackFilterOpen}
      testId={testId}
    />
  );
};
