// when visited from Customer Portal for swap, we only get available from date in the filter (no available to)
// also that date is same as end date of the subscription (instead of usual start of the week)
// based on this we can determine if the user is coming from CP for swap and change the filter and recommendation accordingly
import {
  getSwapDateRange,
  parseSubscriptionEndDate,
  useDeals,
} from '@finn/platform-modules';
import { useMergeFilterValues, usePlpRetentionStore } from '@finn/ua-vehicle';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export const useHandleCpLink = ({
  filterKeys,
  availableFrom,
}: {
  filterKeys: string[];
  availableFrom: string;
}) => {
  const mergeFilterValues = useMergeFilterValues();
  const lastOpenedSubscription = usePlpRetentionStore(
    (state) => state.lastOpenedSubscription
  );
  const setLastOpenedSubscription = usePlpRetentionStore(
    (state) => state.setLastOpenedSubscription
  );
  const { activeDeals, dealsStatus } = useDeals();

  const hasProperFilter = filterKeys.length === 2 && !!availableFrom;

  const selectedSubscription = activeDeals.find((subscription) => {
    return (
      availableFrom ===
      parseSubscriptionEndDate(subscription)?.parsedEndDateString
    );
  });
  const hasSelectedSubscription = !!selectedSubscription;

  const hasNoMatchingDeal =
    dealsStatus === 'success' && !selectedSubscription && hasProperFilter;

  useEffect(() => {
    if (hasNoMatchingDeal) {
      mergeFilterValues(getSwapDateRange(dayjs(availableFrom)));
    }
  }, [hasNoMatchingDeal]);

  useEffect(() => {
    if (!lastOpenedSubscription && hasProperFilter && selectedSubscription) {
      const { parsedEndDate, autoRenewalEndDate } =
        parseSubscriptionEndDate(selectedSubscription);
      setLastOpenedSubscription(selectedSubscription);
      mergeFilterValues(getSwapDateRange(parsedEndDate, autoRenewalEndDate));
    }
  }, [
    hasProperFilter,
    hasSelectedSubscription,
    lastOpenedSubscription,
    setLastOpenedSubscription,
    selectedSubscription,
  ]);
};
