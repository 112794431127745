import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { cn, isMobileApp, useIntl } from '@finn/ui-utils';
import { useCallback } from 'react';

import { setForBusinessCookie } from '../../../helpers';
import { FilterKey, FilterValuesObject } from '../../core';
import {
  useFilterValues,
  useReplaceFilterValues,
  useResultsCount,
} from '../../filters-management';
import { trackFiltersCleared } from '../../tracking';
import { FilterTagList } from './FilterTagList';
import { SortBy } from './individual-filters/SortBy';

const removeProductGroupFilter = (
  filters: FilterValuesObject
): FilterValuesObject => {
  return Object.fromEntries(
    Object.entries(filters).filter(([key]) => key !== FilterKey.PRODUCT_GROUP)
  );
};

export const CombinedFiltersTags = () => {
  const i18n = useIntl();
  const isMobile = useMediaQuery('md');
  const resultsCount = useResultsCount();
  const filterValues = useFilterValues();
  const replaceFilterValues = useReplaceFilterValues();
  const isApp = isMobileApp();
  const isExpPLPReducedFiltersB = useIsABVariant(Features.ExpPLPReducedFilters);
  const isExpPLPReducedFiltersC = useIsCVariant(Features.ExpPLPReducedFilters);
  const isExpPLPReducedFilters =
    (isExpPLPReducedFiltersB || isExpPLPReducedFiltersC) && !isApp;

  const filteredFilterValues = removeProductGroupFilter(filterValues);
  const appliedFiltersCount = Object.entries(filteredFilterValues).length ?? 0;

  const handleReset = useCallback(() => {
    replaceFilterValues({});
    setForBusinessCookie(false);
    trackFiltersCleared();
  }, [replaceFilterValues]);

  return (
    <div
      className={cn(
        'z-[1] flex items-center bg-white md:top-0',
        'sticky top-[72px] mb-1 min-h-12',
        { 'h-10 min-h-0 overflow-hidden': isMobile }
      )}
    >
      <div className="flex items-center">
        <div className="body-12-light inline-block p-2.5 pl-0 pr-2">
          {`${resultsCount} ${i18n.formatMessage(isExpPLPReducedFilters ? 'experiments.reducedFiltersV2.cars' : 'plp.cars')}`}
        </div>
        {appliedFiltersCount > 0 && (
          <Button
            variant="action"
            size="sm"
            className="mr-4"
            data-testid="reset-filters"
            onClick={handleReset}
          >
            {i18n.formatMessage(
              isExpPLPReducedFilters
                ? 'experiments.reducedFiltersV2.clearFilter'
                : 'plp.clearFilter'
            )}
          </Button>
        )}
      </div>

      <>
        <div className="relative mr-2 min-h-[29px] grow">
          <div
            className={cn(
              'absolute bottom-0 left-0 right-0 top-0 h-full w-full'
            )}
          >
            <FilterTagList />
          </div>
        </div>
      </>

      {!isMobile && <SortBy />}
    </div>
  );
};
