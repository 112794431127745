import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import { FilterKey, FiltersResponse } from '../../../core';
import { useDiscoveryConfig } from '../../../core/data/MetadataProvider';
import { FiltersState } from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MiscFilter } from '../atoms/MiscFilter';
import { RangeSelector } from '../atoms/RangeSelector';
import { BusinessBlurb } from '../BusinessBlurb';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type OptionsFilterProps = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: FilterOptionClickFunction;
};

type FilterData = {
  filterKey: FilterKey[];
  availableFilterValues: string[];
};

export const BusinessFilter = ({
  selectedFilters,
  onOptionClick,
  availableFilters,
}: OptionsFilterProps) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked('B2B');
  const config = useDiscoveryConfig();

  const filterData: FilterData = useMemo(() => {
    const allKeysAndValues = [
      {
        key: FilterKey.IS_FOR_BUSINESS,
        value: i18n.formatMessage('plp.forBusiness'),
      },
    ];

    return {
      filterKey: allKeysAndValues.map((item) => item.key),
      availableFilterValues: allKeysAndValues.map((item) => item.value),
    };
  }, [i18n]);

  const selectedRange: [number, number] = useMemo(
    () => [selectedFilters.min_price_msrp, selectedFilters.max_price_msrp],
    [selectedFilters.min_price_msrp, selectedFilters.max_price_msrp]
  );

  const isPriceSelected = useMemo(() => {
    return selectedRange.some((price) => price !== undefined);
  }, [selectedRange]);

  const businessConfig = config?.filterOverrides?.business;

  return (
    <>
      <MiscFilter
        data={filterData}
        onOpen={trackFilterOpen}
        onOptionClick={onOptionClick}
        selectedValues={[Boolean(selectedFilters.is_for_business)]}
        title={
          businessConfig?.title ||
          i18n.formatMessage('plp.businessFilter.title')
        }
        testId={FilterTestID.BusinessFilter}
        hideExtras={businessConfig?.hideExtras}
      >
        {businessConfig?.hideExtras ? null : (
          <span className="body-12-semibold mt-4">
            {i18n.formatMessage('plp.businessFilter.msrpTitle')}
          </span>
        )}

        <RangeSelector
          testIdMin={FilterTestID.MinPriceFilter}
          testIdMax={FilterTestID.MaxPriceFilter}
          selectedRange={selectedRange}
          availableRange={[
            availableFilters.min_price_msrp,
            availableFilters.max_price_msrp,
          ]}
          isRangeSelected={isPriceSelected}
          onRangeSelection={onOptionClick}
          minRangeKey={FilterKey.MIN_PRICE_MSRP}
          maxRangeKey={FilterKey.MAX_PRICE_MSRP}
          minRangeLabel={i18n.formatMessage('plp.minPrice')}
          maxRangeLabel={i18n.formatMessage('plp.maxPrice')}
        />

        {businessConfig?.hideExtras ? null : <BusinessBlurb />}
      </MiscFilter>
    </>
  );
};
