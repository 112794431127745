import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { isMobileApp, useIntl } from '@finn/ui-utils';
import React, { Fragment, ReactNode } from 'react';

import { FilterKey, FiltersResponse } from '../../../core';
import { useDiscoveryConfig } from '../../../core/data/MetadataProvider';
import { FiltersState } from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { AvailabilityFilter } from '../individual-filters/AvailabilityFilter';
import { BrandFilter } from '../individual-filters/BrandFilter';
import { BusinessFilter } from '../individual-filters/BusinessFilter';
import { ConfigFilter } from '../individual-filters/ConfigFilter';
import { DealsFilter } from '../individual-filters/DealsFilter';
import { FeaturesFilter } from '../individual-filters/FeaturesFilter';
import { ModelFilter } from '../individual-filters/ModelFilter';
import { OptionsFilter } from '../individual-filters/OptionsFilters';
import { PowerFilter } from '../individual-filters/PowerFilter';
import { PriceFilter } from '../individual-filters/PriceFilter';
import { TermsFilter } from '../individual-filters/TermsFilter';

export type FilterOptionClickFunction = (filtersObject: FiltersState) => void;

type Props = {
  filtersData: FiltersState;
  availableFilters: FiltersResponse;
  onClick: FilterOptionClickFunction;
  children?: ReactNode;
  aboveFiltersChildren?: ReactNode;
  sortSecondaryFilters?: (a: { name: string }, b: { name: string }) => number;
};

export const FiltersGroup = ({
  filtersData,
  onClick,
  availableFilters,
  children,
  aboveFiltersChildren,
  sortSecondaryFilters,
}: Props) => {
  const i18n = useIntl();
  const config = useDiscoveryConfig();
  const isApp = isMobileApp();
  const isExpPLPReducedFiltersB = useIsABVariant(Features.ExpPLPReducedFilters);
  const isExpPLPReducedFiltersC = useIsCVariant(Features.ExpPLPReducedFilters);

  const isExpPLPReducedFilters =
    (isExpPLPReducedFiltersB || isExpPLPReducedFiltersC) && !isApp;
  const powerFilterConfig = config?.filterOverrides?.power;
  const { is_young_driver: selectedIsYoungDriver } = filtersData;

  const filters = [
    {
      name: 'price',
      component: (
        <PriceFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onClick={onClick}
        />
      ),
      experimentOrder: 4,
    },
    {
      name: 'deals',
      component: (
        <DealsFilter selectedFilters={filtersData} onOptionClick={onClick} />
      ),
      experimentOrder: 2,
    },
    {
      name: 'features',
      component: (
        <FeaturesFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onOptionClick={onClick}
          testId={FilterTestID.FeaturesFilter}
        />
      ),
    },
    {
      name: 'business',
      component: (
        <BusinessFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onOptionClick={onClick}
        />
      ),
      experimentOrder: 5,
    },
    {
      name: 'terms',
      component: (
        <TermsFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onOptionClick={onClick}
          testId={FilterTestID.TermsFilter}
        />
      ),
      experimentOrder: 3,
    },
    {
      name: 'availability',
      component: (
        <AvailabilityFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onClick={onClick}
        />
      ),
    },
    {
      name: 'config',
      component: (
        <ConfigFilter
          title={i18n.formatMessage('plp.gearshift')}
          availableFilters={availableFilters}
          filterKey={FilterKey.GEARSHIFTS}
          selectedFilters={filtersData}
          onOptionClick={onClick}
          testId={FilterTestID.GearshiftFilter}
        />
      ),
      experimentOrder: 1,
    },
    {
      name: 'options',
      component: (
        <OptionsFilter
          selectedFilters={[Boolean(selectedIsYoungDriver)]}
          onOptionClick={onClick}
        />
      ),
    },
    {
      name: 'power',
      component: powerFilterConfig?.hidden ? null : (
        <PowerFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onClick={onClick}
        />
      ),
      experimentOrder: 6,
    },
  ];

  return (
    <div className="mx-4 md:sticky md:top-0 md:ml-0 md:mr-4 md:max-h-screen md:w-[250px] md:self-start md:overflow-y-auto md:overflow-x-hidden md:pr-4">
      {aboveFiltersChildren}

      {isExpPLPReducedFilters && (
        <ConfigFilter
          title={i18n.formatMessage('plp.fuels')}
          filterKey={FilterKey.FUELS}
          selectedFilters={filtersData}
          onOptionClick={onClick}
          availableFilters={availableFilters}
          testId={FilterTestID.FuelFilter}
          expanded={isExpPLPReducedFiltersC}
        />
      )}

      <BrandFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onOptionClick={onClick}
      />

      <ModelFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onOptionClick={onClick}
      />

      {!isExpPLPReducedFilters && (
        <ConfigFilter
          title={i18n.formatMessage('plp.fuels')}
          filterKey={FilterKey.FUELS}
          selectedFilters={filtersData}
          onOptionClick={onClick}
          availableFilters={availableFilters}
          testId={FilterTestID.FuelFilter}
        />
      )}

      <ConfigFilter
        title={i18n.formatMessage('plp.cartype')}
        filterKey={FilterKey.CAR_TYPES}
        selectedFilters={filtersData}
        onOptionClick={onClick}
        availableFilters={availableFilters}
        testId={FilterTestID.CarTypeFilter}
      />

      {(sortSecondaryFilters ? filters.sort(sortSecondaryFilters) : filters)
        ?.filter(
          ({ name }) =>
            !isExpPLPReducedFilters ||
            !['availability', 'features', 'options'].includes(name)
        )
        .sort((a, b) =>
          isExpPLPReducedFilters ? a.experimentOrder - b.experimentOrder : 0
        )
        .map(({ name, component }) => (
          <Fragment key={name}>{component}</Fragment>
        ))}
      {children}
    </div>
  );
};
