import { Button } from '@finn/design-system/atoms/button';
import { Slider } from '@finn/design-system/atoms/slider';
import { useIntl } from '@finn/ui-utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FilterKey } from '../../../core';
import { type Range } from '../../helpers';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { RangeHelperInput } from './RangeHelperInput';

type Props = {
  selectedRange: Range;
  availableRange: [number, number];
  isRangeSelected: boolean;
  onRangeSelection: FilterOptionClickFunction;
  maxRangeKey: FilterKey;
  minRangeKey: FilterKey;
  testIdMin?: string;
  testIdMax?: string;
  suffix?: string;
  minRangeLabel?: string;
  maxRangeLabel?: string;
};

export const RangeSelector = ({
  selectedRange,
  availableRange,
  isRangeSelected,
  onRangeSelection,
  maxRangeKey,
  minRangeKey,
  testIdMin,
  testIdMax,
  suffix,
  minRangeLabel,
  maxRangeLabel,
}: Props) => {
  const i18n = useIntl();

  const adjustedAvailableRange = [availableRange[0], availableRange[1]];

  const minRange = availableRange[0];
  const maxRange = availableRange[1];

  const [currentRange, setCurrentRange] = useState<Range>([
    selectedRange[0] || availableRange[0],
    selectedRange[1] || availableRange[1],
  ]);

  const newMax = useMemo(() => {
    if (selectedRange[1] && selectedRange[1] > adjustedAvailableRange[1]) {
      onRangeSelection({ [maxRangeKey]: undefined });

      return adjustedAvailableRange[1];
    }
  }, [selectedRange[1], adjustedAvailableRange[1], onRangeSelection]);

  const newMin = useMemo(() => {
    if (selectedRange[0] && selectedRange[0] < adjustedAvailableRange[0]) {
      onRangeSelection({ [minRangeKey]: undefined });

      return adjustedAvailableRange[0];
    }
  }, [selectedRange[0], adjustedAvailableRange[0], onRangeSelection]);

  useEffect(() => {
    if (isRangeSelected) {
      let min;
      let max;

      if (!selectedRange[0] && selectedRange[1]) {
        max = newMax || currentRange[1];
        setCurrentRange([adjustedAvailableRange[0], max]);
      } else if (selectedRange[0] && !selectedRange[1]) {
        min = newMin || currentRange[0];
        setCurrentRange([min, adjustedAvailableRange[1]]);
      } else if (selectedRange[0] && selectedRange[1]) {
        min = newMin || currentRange[0];
        max = newMax || currentRange[1];
        setCurrentRange([min, max]);
      }
    } else {
      setCurrentRange([adjustedAvailableRange[0], adjustedAvailableRange[1]]);
    }
  }, [availableRange]);

  const updateTimeoutRef = useRef<number | null>(null);

  const handleMin = useCallback(
    (nextValue: number) => {
      if (
        nextValue < minRange ||
        nextValue > maxRange ||
        (currentRange[1] && nextValue > currentRange[1])
      ) {
        return;
      }

      onRangeSelection({
        [minRangeKey]: nextValue === minRange ? undefined : nextValue,
      });
    },
    [onRangeSelection, minRange]
  );

  const updateRangeMin = useCallback(
    (nextValue: number) => {
      setCurrentRange([nextValue, currentRange[1]]);
      if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current);
      updateTimeoutRef.current = setTimeout(
        () => handleMin(nextValue),
        1000
      ) as unknown as number;
    },
    [setCurrentRange, currentRange[1], handleMin, updateTimeoutRef]
  );

  const handleMax = useCallback(
    (nextValue: number) => {
      if (
        nextValue < minRange ||
        nextValue > maxRange ||
        (currentRange[0] && nextValue < currentRange[0])
      ) {
        return;
      }

      onRangeSelection({
        [maxRangeKey]: nextValue === maxRange ? undefined : nextValue,
      });
    },
    [maxRange, onRangeSelection]
  );

  const updateRangeMax = useCallback(
    (nextValue: number) => {
      setCurrentRange([currentRange[0], nextValue]);
      if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current);
      updateTimeoutRef.current = setTimeout(
        () => handleMax(nextValue),
        1000
      ) as unknown as number;
    },
    [setCurrentRange, currentRange[0], handleMin, updateTimeoutRef]
  );

  const handleChange = useCallback(
    (value: [number | undefined, number | undefined]) => {
      setCurrentRange(value);
    },
    [setCurrentRange]
  );

  const handleRangeSelection = useCallback(() => {
    onRangeSelection({
      [minRangeKey]: currentRange[0] === minRange ? undefined : currentRange[0],
      [maxRangeKey]: currentRange[1] === maxRange ? undefined : currentRange[1],
    });
  }, [onRangeSelection, currentRange, minRange, maxRange]);

  const handleResetForm = useCallback(() => {
    setCurrentRange([minRange, maxRange]);
    onRangeSelection({
      [minRangeKey]: undefined,
      [maxRangeKey]: undefined,
    });
  }, [setCurrentRange, minRange, maxRange, onRangeSelection]);

  return (
    <div className="-mx-2 flex w-full flex-col overflow-hidden pl-2 pt-1">
      <div className="m-3 p-1" data-testid="price-selector-slider">
        <Slider
          variant="secondary"
          value={currentRange}
          min={minRange}
          max={maxRange}
          className="-ml-3.5 mb-4 w-[calc(100%+1.75rem)]"
          onValueChange={handleChange}
          onValueCommit={handleRangeSelection}
        />
      </div>

      <div
        className="m-px mb-3 flex items-center justify-between gap-6 px-0.5"
        data-testid="price-selector-inputs"
      >
        <RangeHelperInput
          suffix={suffix}
          label={minRangeLabel}
          min={minRange}
          max={maxRange}
          value={
            currentRange[0] ? Math.round(currentRange[0]) : currentRange[0]
          }
          defaultValue={minRange}
          currentRange={currentRange}
          onChange={updateRangeMin}
          testId={testIdMin}
        />
        <RangeHelperInput
          suffix={suffix}
          label={maxRangeLabel}
          min={minRange}
          max={maxRange}
          value={
            currentRange[1] ? Math.round(currentRange[1]) : currentRange[1]
          }
          defaultValue={maxRange}
          currentRange={currentRange}
          onChange={updateRangeMax}
          testId={testIdMax}
        />
      </div>

      <Button
        size="sm"
        variant="action"
        className="w-full"
        onClick={handleResetForm}
        disabled={!isRangeSelected}
      >
        {i18n.formatMessage('plp.reset')}
      </Button>
    </div>
  );
};
