import { Button } from '@finn/design-system/atoms/button';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from '@finn/design-system/atoms/drawer';
import { useIntl } from '@finn/ui-utils';
import { ReactNode, useCallback, useEffect } from 'react';

import { FiltersResponse } from '../../core';
import {
  defaultFilterValues,
  useAvailableFiltersForState,
  useFilterState,
  useFilterValues,
  useInitialFilterValues,
  useMergeFilterValues,
  useResultsCount,
} from '../../filters-management';
import {
  trackFilterDrawerClosed,
  trackFilterDrawerOpened,
} from '../../tracking';
import { FiltersGroup } from './grouped-filters/FiltersGroup';

type Props = {
  isOpen: boolean;
  availableFilters: FiltersResponse;
  onToggleDrawer: (isOpen: boolean) => void;
  aboveFiltersChildren?: ReactNode;
  children?: ReactNode;
  sortSecondaryFilters?: (a: { name: string }, b: { name: string }) => number;
};

export const FiltersDrawer = ({
  isOpen,
  availableFilters,
  onToggleDrawer,
  aboveFiltersChildren,
  children,
  sortSecondaryFilters,
}: Props) => {
  const i18n = useIntl();
  const mergeFilterValues = useMergeFilterValues();

  const filterValues = useFilterValues();
  const initialValues = useInitialFilterValues(filterValues);
  const [filterState, setFilterState] = useFilterState(
    availableFilters,
    initialValues
  );
  const availableFiltersFromState = useAvailableFiltersForState(filterState);
  const resultsCount = useResultsCount(filterState);

  useEffect(() => {
    if (isOpen) {
      trackFilterDrawerOpened();
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    trackFilterDrawerClosed();
    setFilterState(initialValues);
    onToggleDrawer(false);
  }, [initialValues]);

  const handleReset = useCallback(() => {
    setFilterState(defaultFilterValues);
  }, [initialValues]);

  const handleSubmit = useCallback(() => {
    mergeFilterValues(filterState);
    onToggleDrawer(false);
  }, [filterState]);

  return (
    <Drawer open={isOpen} onClose={handleClose} direction="bottom">
      <DrawerContent
        className="!-p-4"
        contentClassName="py-0"
        title={i18n.formatMessage('plp.filters')}
      >
        <div className="z-overlay -mx-4 h-full overflow-auto">
          <FiltersGroup
            sortSecondaryFilters={sortSecondaryFilters}
            aboveFiltersChildren={aboveFiltersChildren}
            filtersData={filterState}
            availableFilters={availableFiltersFromState}
            onClick={setFilterState}
          >
            {children}
          </FiltersGroup>
        </div>

        <DrawerFooter className="h-min flex-row py-4">
          <Button
            className="w-1/3"
            variant="secondaryWhite"
            size="lg"
            onClick={handleReset}
          >
            {i18n.formatMessage('general.reset')}
          </Button>
          <Button
            className="w-2/3"
            variant="primary"
            size="lg"
            onClick={handleSubmit}
          >
            {i18n.formatMessage('plp.showResultsWithCount', {
              count: resultsCount,
            })}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
