import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import { FilterKey, FiltersResponse } from '../../../core';
import { FiltersState } from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { FilterAccordion } from '../atoms/FilterAccordion';
import { RangeSelector } from '../atoms/RangeSelector';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type Props = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onClick: FilterOptionClickFunction;
};

export const PriceFilter = ({
  selectedFilters,
  availableFilters,
  onClick,
}: Props) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked('price');
  const selectedRange: [number, number] = useMemo(
    () => [selectedFilters.min_price, selectedFilters.max_price],
    [selectedFilters.min_price, selectedFilters.max_price]
  );

  const isPriceSelected = useMemo(() => {
    return selectedRange.some((price) => price !== undefined);
  }, [selectedRange]);

  return (
    <FilterAccordion
      title={i18n.formatMessage('plp.price')}
      count={isPriceSelected && 1}
      onOpen={trackFilterOpen}
      testId={FilterTestID.PriceFilter}
    >
      <RangeSelector
        testIdMin={FilterTestID.MinPriceFilter}
        testIdMax={FilterTestID.MaxPriceFilter}
        selectedRange={selectedRange}
        availableRange={[
          availableFilters.min_price,
          availableFilters.max_price,
        ]}
        isRangeSelected={isPriceSelected}
        onRangeSelection={onClick}
        minRangeKey={FilterKey.MIN_PRICE}
        maxRangeKey={FilterKey.MAX_PRICE}
        minRangeLabel={i18n.formatMessage('plp.minPrice')}
        maxRangeLabel={i18n.formatMessage('plp.maxPrice')}
      />
    </FilterAccordion>
  );
};
