import { useSearchParams } from 'next/navigation';
import { NextRouter } from 'next/router';

import { useDiscoveryConfig } from '../../core/data/MetadataProvider';

export function useCurrentPage(): number | null {
  const searchParams = useSearchParams();
  const { router } = useDiscoveryConfig();
  const page = (router as NextRouter)?.query?.page || searchParams?.get('page');
  const pageNumber = Number(page);

  return Number.isFinite(pageNumber) && pageNumber > 0 ? Number(page) : null;
}
