import { Skeleton } from '@finn/design-system/atoms/skeleton';
import { SWR_CACHE_TIME_LONG } from '@finn/ua-constants';
import { ProductCard, usePlpRetentionStore } from '@finn/ua-vehicle';
import { useIntl } from '@finn/ui-utils';
import { useRouter } from 'next/compat/router';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import useSWR from 'swr';

import { useHandleCpLink } from '~/modules/recommendation/hooks/useHandleCpLink';
import { useRecommendationTracker } from '~/modules/recommendation/hooks/useRecommendationTracker';
import { recommendationFetcher } from '~/modules/recommendation/recommendataionFetcher';
import { getLastDateOfWeekOfSubscriptionEnd } from '~/modules/recommendation/utils';

export const RecommendationSection: React.FC<{
  filters: Record<string, any>;
}> = ({ filters }) => {
  const lastOpenedSubscription = usePlpRetentionStore(
    (state) => state.lastOpenedSubscription
  );
  const endOfWeek = getLastDateOfWeekOfSubscriptionEnd(lastOpenedSubscription);
  const router = useRouter();
  const searchParams = useSearchParams();
  const i18n = useIntl();
  const availableFrom = (router?.query?.available_from ||
    searchParams?.get('available_from')) as string;
  const availableTo = (router?.query?.available_to ||
    searchParams?.get('available_to')) as string;
  const { data, isLoading } = useSWR(
    lastOpenedSubscription,
    recommendationFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: SWR_CACHE_TIME_LONG,
    }
  );

  const vehicles = data?.fleetData?.length ? data?.fleetData : [];
  const filterKeys = Object.keys(filters || {});
  const hasExactNumberOfFilters = filterKeys.length === 3;

  useHandleCpLink({ filterKeys, availableFrom });

  const { onRecommendationClick } = useRecommendationTracker({
    data,
    hasExactNumberOfFilters,
  });

  const title = i18n.formatMessage('plp.recommendationTitle');
  const bottomTitle = i18n.formatMessage('plp.afterRecommendationTitle');

  if (
    !availableFrom ||
    !availableTo ||
    availableTo !== endOfWeek ||
    !hasExactNumberOfFilters ||
    (!isLoading && !vehicles.length)
  ) {
    return null;
  }

  if (isLoading && !vehicles.length) {
    return (
      <div className="my-6">
        <Skeleton className="h-[100px] w-full" />
      </div>
    );
  }

  return (
    <div className="my-6">
      <h5 className="global-t5-semibold mb-6">{title}</h5>
      <div>
        <div className="overflow-x-scroll pb-4 sm:overflow-x-auto sm:pb-0">
          <div className="xs:grid flex w-full grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-x-4 gap-y-6 md:grid-cols-[repeat(3,minmax(280px,1fr))]">
            {vehicles.map((vehicle, index) => (
              <div
                key={vehicle.id}
                data-testid="product-card"
                className="w-80vw flex-[0_0_80vw] sm:w-auto sm:flex-auto"
                onClick={() => {
                  onRecommendationClick(vehicle.id);
                }}
              >
                <ProductCard
                  position={index + 1}
                  vehicle={vehicle}
                  shouldPreload
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <h5 className="global-t5-semibold mb-6 mt-14">{bottomTitle}</h5>
    </div>
  );
};
