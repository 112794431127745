import { RadioGroup, RadioItem } from '@finn/design-system/atoms/radio';
import { useCallback } from 'react';

import { FilterKey } from '../../../core';
import { FilterData, FilterToggleData, TestProps } from '../../helpers';
import { trackFilterOptionClicked } from '../../tracking';
import { FilterAccordion } from './FilterAccordion';

export type SelectProps = {
  value: string | number | boolean;
  selected?: boolean;
  onClick: (filterObject: object) => void;
  filterKey: FilterKey;
  selectedValues?: any;
  shouldReturnArray?: boolean;
};

const handleSingleSelect = ({
  value,
  selected,
  onClick,
  filterKey,
  shouldReturnArray = true,
}: SelectProps) => {
  let updatedValue: string | number | boolean | (string | number | boolean)[];
  if (shouldReturnArray) {
    updatedValue = selected ? [] : [value];
  } else {
    updatedValue = selected ? undefined : value;
  }
  onClick({ [filterKey]: updatedValue });
};

type Props = TestProps & {
  title: string;
  selectedValue?: string | number;
  defaultValue?: string | number;
  data: FilterData;
  onClick: (filterObject: object) => void;
  onOpen?(isOpen: boolean): void;
  shouldReturnArray?: boolean;
};

export const SingleSelectFilter = ({
  title,
  testId,
  selectedValue,
  defaultValue,
  data,
  onClick,
  onOpen,
  shouldReturnArray = true,
}: Props) => {
  const { filterKey, availableFilterValues } = data;

  const handleToggle = useCallback(
    (value: string) => {
      const selectProps = {
        value,
        onClick,
        filterKey,
        selectedValue,
        shouldReturnArray,
      };

      if (value !== undefined && value !== null) {
        trackFilterOptionClicked(filterKey, value);
      }

      handleSingleSelect(selectProps);
    },
    [filterKey, onClick, selectedValue]
  );

  return (
    <FilterAccordion
      title={title}
      onOpen={onOpen}
      testId={testId}
      count={selectedValue && 1}
    >
      <div className="w-full pl-1.5">
        <RadioGroup
          orientation="vertical"
          value={String(selectedValue || defaultValue)}
          onValueChange={handleToggle}
          className="mt-1"
        >
          {/**
            For unclear, likely historical reason, checkbox has fixed height of 42px(in plp filters).
            but Radio button does not have fixed height, instead it has internal padding.
            To make it consistent with checkbox, we are adding fixed height to radio button.
            But long term solution should be to remove fixed height from checkbox and radio button.
            Which we will add during checkbox and radio atoms migration.
            TODO
           */}
          {(availableFilterValues as FilterToggleData[]).map(
            ({ value, label, disabled }: FilterToggleData) => (
              <RadioItem
                key={label + value}
                value={String(value)}
                label={label}
                className="-ml-1.5"
                disabled={disabled}
              />
            )
          )}
        </RadioGroup>
      </div>
    </FilterAccordion>
  );
};
