import { traceabilityHeadersBrowser, useSession } from '@finn/ui-utils';
import axios from 'axios';
import useSWR from 'swr';

export type LoyaltyInfo = {
  level: string;
  expiration_date: string;
  total_points: number;
  total_coins?: number;
};

export type LoyaltyRewardOption = {
  name: string;
  description: string;
  image: {
    url: string;
  };
  value: number;
};

export type LoyaltyReward = {
  type: 'mileage' | 'voucher';
  options: LoyaltyRewardOption[];
};

export type LoyaltyBenefitsData = {
  rewards: LoyaltyReward[];
};

const LOYALTY_INFO_ENDPOINT = '/api/getLoyaltyInfo';
const loyaltyInfoFetcher = async ({
  contactId,
}: {
  contactId?: string;
}): Promise<LoyaltyInfo | null> => {
  try {
    const response = await axios.post(
      LOYALTY_INFO_ENDPOINT,
      { contactId },
      {
        headers: traceabilityHeadersBrowser(),
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

const DEDUPING_INTERVAL = 300000;

// todo: remove contactId in the future as we'll take it from the session
// for now it's needed as some checkout page will use it for unauthenticated users
export const useGetLoyaltyInfo = (contactId?: string) => {
  const [session] = useSession();

  const { data, isLoading } = useSWR(
    session || contactId ? { key: 'loyaltyInfo', contactId } : null,
    loyaltyInfoFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
      dedupingInterval: DEDUPING_INTERVAL,
    }
  );

  return { data, isLoading };
};
