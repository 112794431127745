import { useIntl } from '@finn/ui-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterKey, FiltersResponse } from '../../../core';
import {
  FiltersState,
  useIsPlpPage,
  useIsProductGridFiltered,
  useProductGridModels,
} from '../../../filters-management';
import { FilterData, FilterTestID, FilterToggleData } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { FilterAccordion } from '../atoms/FilterAccordion';
import { getSelectedCount } from '../atoms/MiscFilter';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { ModelSubFilter } from './ModelSubFilter';

type Props = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: FilterOptionClickFunction;
};
export const ModelFilter = ({
  selectedFilters,
  availableFilters,
  onOptionClick,
}: Props) => {
  const i18n = useIntl();
  const [expanded, setExpanded] = useState(false);

  const isProductGrid = !useIsPlpPage();
  const isProductGridFiltered = useIsProductGridFiltered();
  const productGridModels = useProductGridModels();

  const { brands: selectedBrands, models: selectedModels } = useMemo(
    () => selectedFilters,
    [selectedFilters.brands, selectedFilters.models]
  );

  const trackFilterOpen = useTrackFilterCategoryClicked(FilterKey.MODELS);

  const selectedCount = useMemo(
    () => getSelectedCount(selectedModels),
    [getSelectedCount, selectedModels.length]
  );

  const selectedBrandsData = useMemo(() => {
    if (selectedBrands.length === 0) return;

    return selectedBrands
      .map((selectedBrand) =>
        availableFilters.brands.find((brand) => brand.id === selectedBrand)
      )
      .filter(Boolean);
  }, [selectedBrands, availableFilters.brands]);

  const brandsModels = useMemo(() => {
    const brandsData = {};

    selectedBrandsData?.map((selectedBrand) => {
      brandsData[selectedBrand.id] = selectedBrand.models.map(
        ({ id, available }) => {
          const hidden =
            isProductGrid &&
            (isProductGridFiltered
              ? !productGridModels.includes(id)
              : !available);

          if ((isProductGrid && !hidden) || !isProductGrid) {
            return {
              value: id,
              label: id,
              disabled: !available,
              selected: selectedModels?.includes(id),
              hidden,
            };
          }
        }
      );
    });

    Object.entries(brandsData).map(([brandDataKey, brandDataValue]) => {
      brandsData[brandDataKey] = (brandDataValue as any[]).filter(
        (element) => element !== undefined
      );
    });

    const orderedBrandsData = Object.keys(brandsData)
      .sort()
      .reduce((obj, key) => {
        obj[key] = brandsData[key];

        return obj;
      }, {});

    return orderedBrandsData;
  }, [
    selectedBrandsData,
    selectedModels,
    isProductGrid,
    isProductGridFiltered,
    productGridModels,
  ]);

  const groupedModels = useMemo(() => {
    return Object.entries(brandsModels)?.map(([brand, models]) => {
      const data: FilterData = {
        filterKey: FilterKey.MODELS,
        brands: selectedBrands,
        selectedValues: selectedModels,
        availableFilterValues: models as FilterToggleData[],
      };

      return (
        <ModelSubFilter
          key={brand}
          data={data}
          brand={brand}
          onOptionClick={onOptionClick}
        />
      );
    });
  }, [brandsModels, selectedBrands, selectedModels]);

  useEffect(() => {
    if (selectedBrands.length === 0) {
      setExpanded(false);
    }
  }, [selectedBrands]);

  const handleAccordionClick = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  return (
    <FilterAccordion
      title={i18n.formatMessage('plp.model')}
      count={selectedCount}
      expanded={expanded}
      onClick={handleAccordionClick}
      disabled={!selectedBrandsData}
      onOpen={trackFilterOpen}
      testId={FilterTestID.ModelFilter}
    >
      <div className="w-full py-2 first:pt-0 last:pb-0">{groupedModels}</div>
    </FilterAccordion>
  );
};
