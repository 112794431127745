import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@finn/design-system/atoms/accordion';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { FilterTestID, TestProps } from '../../helpers';

type Props = TestProps & {
  title: string | ReactElement;
  warning?: string;
  reverse?: boolean;
  onClick?: () => void;
  count?: number;
  disabled?: boolean;
  expanded?: boolean;
  onOpen?(isOpen: boolean): void;
  children: ReactNode;
};

export const FilterAccordion = ({
  title,
  warning,
  reverse,
  onClick,
  count,
  disabled,
  expanded,
  onOpen,
  testId,
  children,
}: Props) => {
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    if (open !== expanded) {
      setIsOpen(expanded);
    }
  }, [expanded]);

  return (
    <Accordion
      type="multiple"
      className="w-full"
      value={open ? [String(title)] : []}
      disabled={disabled}
      onValueChange={(val) => {
        if (val) {
          const isOpen = val[0] === String(title);

          onOpen?.(isOpen);
          setIsOpen(isOpen);
          onClick?.();
        }
      }}
      data-testid={testId}
    >
      <AccordionItem value={String(title)}>
        <AccordionTrigger className="!py-4">
          <div className="flex min-h-7 items-center gap-2">
            <span
              className={`${reverse ? 'body-12-semibold' : 'body-14-semibold'}`}
            >
              {title}
            </span>
            {warning && (
              <div className="body-12-regular !text-steel">{warning}</div>
            )}
            {count && (
              <span className="body-14-semibold !bg-snow !p-2">{count}</span>
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent
          alwaysMounted={false}
          className="ml-[2px]"
          wrapperClassName="before:hidden after:hidden"
          data-testid={`${testId}-${FilterTestID.Details}`}
        >
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
