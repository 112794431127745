import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import { FilterKey, FiltersResponse } from '../../../core';
import {
  FiltersState,
  useIsPlpPage,
  useIsProductGridFiltered,
  useOriginalProductGridFilters,
  useProductGridBrands,
} from '../../../filters-management';
import { FilterData, FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MultiSelectFilter } from '../atoms/MultiSelectFilter';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type Props = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: FilterOptionClickFunction;
  expanded?: boolean;
};

export const BrandFilter = ({
  selectedFilters,
  availableFilters,
  onOptionClick,
  expanded,
}: Props) => {
  const i18n = useIntl();
  const isProductGrid = !useIsPlpPage();
  const isProductGridFiltered = useIsProductGridFiltered();
  const productGridBrands = useProductGridBrands({ getAllBrandData: false });

  const originalProductGridFilters = useOriginalProductGridFilters();

  const finalAvailableFilters = useMemo(
    () =>
      isProductGrid && !isProductGridFiltered
        ? originalProductGridFilters
        : availableFilters,
    [
      isProductGrid,
      isProductGridFiltered,
      originalProductGridFilters,
      availableFilters,
    ]
  );

  const trackFilterOpen = useTrackFilterCategoryClicked(FilterKey.BRANDS);

  const selectedBrands = useMemo(
    () => selectedFilters.brands,
    [selectedFilters.brands]
  );

  const data = useMemo<FilterData>(
    () => ({
      filterKey: FilterKey.BRANDS,
      selectedValues: selectedBrands,
      availableFilterValues: finalAvailableFilters.brands.map(
        ({ id, available, helper_brand_logo }) => ({
          value: id,
          label: id,
          image: helper_brand_logo?.url,
          disabled: !available,
          selected: selectedBrands?.includes(id),
          hidden:
            isProductGrid &&
            (isProductGridFiltered
              ? !productGridBrands.includes(id)
              : !available),
        })
      ),
    }),
    [
      selectedBrands,
      availableFilters.brands,
      isProductGrid,
      isProductGridFiltered,
      productGridBrands,
    ]
  );

  return (
    <MultiSelectFilter
      expanded={expanded}
      title={i18n.formatMessage('plp.brand')}
      data={data}
      onOpen={trackFilterOpen}
      onOptionClick={onOptionClick}
      testId={FilterTestID.BrandFilter}
    />
  );
};
