import { toISODate } from '@finn/platform-modules';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import {
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
} from '@finn/ua-vehicle';
import { isEmpty, isNil } from 'lodash';
import { useEffect } from 'react';

import { haveFilterValuesChanged } from '../modules/products/filters/utils';

type FilterTrackingObject = {
  type: string;
  value: unknown;
};

const trackProductListFiltered = (
  filterValues: FilterValuesObject,
  filtersResponse: FiltersResponse,
  total: number
): void => {
  const excludedKeys = new Set([
    // Should not appear in filters
    'sort',

    // Should appear as a single `price` object
    'min_price',
    'max_price',

    // Should appear as a single `availability` object
    'available_from',
    'available_to',
  ]);

  const filterKeys = Object.keys(filterValues).filter(
    (key) => !excludedKeys.has(key)
  );

  const filterTrackingObjects: FilterTrackingObject[] = filterKeys
    .filter((key) =>
      Array.isArray(filterValues[key as FilterKey])
        ? !isEmpty(filterValues[key as FilterKey])
        : !isNil(filterValues[key as FilterKey])
    )
    .map((key) => {
      return {
        type: key,
        value: filterValues[key as FilterKey],
      };
    });

  if (filterValues.min_price || filterValues.max_price) {
    filterTrackingObjects.push({
      type: 'price',
      value: {
        start: filterValues.min_price ?? filtersResponse.min_price,
        end: filterValues.max_price ?? filtersResponse.max_price,
      },
    });
  }

  if (filterValues.available_from || filterValues.available_to) {
    filterTrackingObjects.push({
      type: 'availability',
      value: {
        start: filterValues.available_from
          ? toISODate(filterValues.available_from)
          : undefined,
        end: filterValues.available_to
          ? toISODate(filterValues.available_to)
          : undefined,
      },
    });
  }

  interactionTrackingEvent(TrackingEventName.PRODUCT_LIST_FILTERED, {
    filters: filterTrackingObjects,
    sort: filterValues.sort,
    total_count: total,
  });
};

export const useTrackingEffect = (
  total: number,
  isRouterReady: boolean,
  filtersResponse: FiltersResponse,
  trackedFilterValues: FilterValuesObject,
  previousFilterValues: FilterValuesObject
) => {
  useEffect(() => {
    if (!isRouterReady) {
      return;
    }

    if (haveFilterValuesChanged(trackedFilterValues, previousFilterValues)) {
      trackProductListFiltered(trackedFilterValues, filtersResponse, total);
    }
  }, [
    total,
    isRouterReady,
    filtersResponse,
    trackedFilterValues,
    previousFilterValues,
  ]);
};
