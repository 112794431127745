import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import { SortKey } from '../../core';

export type SortByOption = {
  label: string;
  value: SortKey | undefined;
};

export const useSortByOptions = () => {
  const i18n = useIntl();

  return useMemo<SortByOption[]>(
    () => [
      {
        label: i18n.formatMessage('plp.defaultSort'),
        value: SortKey.DEFAULT,
      },
      {
        label: i18n.formatMessage('plp.sortLastAdded'),
        value: SortKey.LAST_ADDED,
      },
      {
        label: i18n.formatMessage('plp.sortAsc'),
        value: SortKey.ASC,
      },
      {
        label: i18n.formatMessage('plp.sortDesc'),
        value: SortKey.DESC,
      },
      {
        label: i18n.formatMessage('plp.sortEarliestAvailabilityDate'),
        value: SortKey.AVAILABILITY,
      },
    ],
    [i18n]
  );
};
