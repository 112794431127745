import { cn } from '@finn/ui-utils';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { handlePageTracking } from '../../tracking';

type Props = {
  page: number;
  selected?: boolean;
  disabled?: boolean;
  shallow?: boolean;
};

export const PaginationPageLink = ({
  page,
  selected = false,
  disabled = false,
  shallow = false,
}: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { router } = useDiscoveryConfig();

  const linkHref = useMemo(
    () => ({
      pathname,
      query: {
        ...router.query,
        ...Object.fromEntries(searchParams || new Map()),
        page,
      },
    }),
    [router, page, searchParams, pathname]
  );

  return (
    <Link
      scroll={false}
      shallow={shallow}
      href={linkHref}
      className={cn(
        'mx-1 inline-block min-w-8 cursor-pointer py-2 text-center sm:mx-2',
        {
          'border-0 border-b-2 border-solid border-black': selected,
          'pointer-events-none cursor-default': disabled,
        }
      )}
      onClick={() =>
        handlePageTracking({ type: 'page-number', pageNumber: page })
      }
    >
      <span className="body-16-semibold">{page}</span>
    </Link>
  );
};
