import { constructFormattedPriceResponse, useIntl } from '@finn/ui-utils';
import { useCallback } from 'react';

export const useFormatPrice = () => {
  const i18n = useIntl();

  return useCallback(
    (value: number, maximumFractionDigits: number = 2, forceDigits?: boolean) =>
      constructFormattedPriceResponse(
        i18n,
        value,
        maximumFractionDigits,
        forceDigits
      ),
    [i18n]
  );
};
