import { useMemo } from 'react';

import { FilterKey } from '../../core';
import { PricingType } from '../../product-pricing';
import { VehicleView } from '../helpers/filter-helpers';
import { FiltersState } from './useAvailableFiltersForState';
import { useImplicitFilters } from './useImplicitFilters';

export const defaultFilterValues = {
  [FilterKey.BRANDS]: [],
  [FilterKey.MODELS]: [],
  [FilterKey.FUELS]: [],
  [FilterKey.CAR_TYPES]: [],
  [FilterKey.MIN_PRICE]: undefined,
  [FilterKey.MAX_PRICE]: undefined,
  [FilterKey.MIN_PRICE_MSRP]: undefined,
  [FilterKey.MAX_PRICE_MSRP]: undefined,
  [FilterKey.TERMS]: [],
  [FilterKey.FEATURES]: [],
  [FilterKey.AVAILABLE_TO]: null,
  [FilterKey.AVAILABLE_FROM]: null,
  [FilterKey.HAS_DEALS]: null,
  [FilterKey.GEARSHIFTS]: [],
  [FilterKey.HAS_HITCH]: null,
  [FilterKey.IS_FOR_BUSINESS]: null,
  [FilterKey.IS_YOUNG_DRIVER]: null,
  [FilterKey.SORT]: undefined,
};

export type Filters = {
  sort?: string;
  sort_co2?: string;
  brands?: Array<string>;
  cartypes?: Array<string>;
  models?: Array<string>;
  gearshifts?: Array<string>;
  terms?: Array<string>;
  pricing_type?: PricingType;
  cug_id?: string;
  fuels?: Array<string>;
  colors?: Array<string>;
  view?: VehicleView;
  'coming-soon'?: Array<string> | string;
  retention?: Array<string> | string;
  has_hitch?: boolean;
  is_young_driver?: boolean;
  is_for_business?: boolean;
  min_price?: string;
  max_price?: string;
  has_deals?: boolean;
  available_from?: string;
  available_to?: string;
  offer_type?: string;
  sort_by_popularity?: boolean;
  zipcode?: string;
};

export type UseInitialFilterValuesType = FiltersState;

export type InitialFilterKeysMap = { [x: string]: { [x: string]: string } };

export const useInitialFilterValues = (
  filterValues: FiltersState
): FiltersState => {
  const implicitFilters = useImplicitFilters();

  return useMemo(() => {
    const defaultValues: FiltersState = defaultFilterValues;

    return {
      ...defaultValues,
      ...filterValues,
      ...implicitFilters,
    };
  }, [filterValues, defaultFilterValues, implicitFilters]);
};
