import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Deal } from '@finn/platform-modules';
import { useCurrentLocale } from '@finn/ui-utils';
import isEqual from 'lodash/isEqual';
import { RefObject, useEffect, useMemo, useRef } from 'react';

import {
  DEFAULT_LIMIT,
  FilterKey,
  FilterValuesObject,
  GetVehiclesResponse,
  useProductListItems,
  VehicleViewKey,
} from '../../core';
import { useFilterValues } from '../../filters-management';
import { GenericVehicleDetails } from '../../product-cards';
import { useCurrentPage } from './useCurrentPage';

const MOBILE_SCROLL_OFFSET = 120;
const SCROLL_OFFSET = 60;

const getStatus = ({
  isLoading,
  isValidating,
  offset,
  products,
}: {
  isLoading: boolean;
  isValidating: boolean;
  offset: number | null;
  products: GenericVehicleDetails[];
}) => {
  if (isValidating) {
    return 'validating' as const;
  }
  if (isLoading && !offset && products.length === 0) {
    return 'loading' as const;
  }

  return 'success' as const;
};

export const useFiltersForProducts = ({
  initialFilters,
  subscriptions,
}: {
  initialFilters: FilterValuesObject;
  subscriptions?: Deal[];
}) => {
  const { isUSA } = useCurrentLocale();
  const filtersFromContext = useFilterValues();

  // we need to memoize this to ensure that return object link is stable,
  // as it used inside useEffect dependencies in some places
  return useMemo(() => {
    const result: FilterValuesObject = {
      ...filtersFromContext,
      ...initialFilters,
    };

    const hasSubscriptions = subscriptions?.length > 0;
    const hasRetentionFilter = result[FilterKey.RETENTION];

    const isViewAlreadySet = Boolean(result[FilterKey.VIEW]);
    const isRetentionCustomer = hasRetentionFilter || hasSubscriptions;

    if (!isViewAlreadySet) {
      result[FilterKey.VIEW] =
        isRetentionCustomer || isUSA
          ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
          : VehicleViewKey.AVAILABLE;
    }

    return result;
  }, [filtersFromContext, initialFilters, subscriptions?.length, isUSA]);
};

export const useProducts = ({
  infinite,
  gridRef,
  filters,
  limit = DEFAULT_LIMIT,
  offset,
  fallbackData,
}: {
  infinite?: boolean;
  limit?: number;
  offset?: number;
  filters?: ReturnType<typeof useFiltersForProducts>;
  fallbackData?: GetVehiclesResponse;
  gridRef?: RefObject<HTMLDivElement>;
}) => {
  const isMobile = useMediaQuery('md');
  const page = useCurrentPage() ?? 1;
  const offsetToUse = infinite ? offset : limit * (page - 1);

  const productsRes = useProductListItems(
    {
      zipCode: undefined,
      filters,
      offset: offsetToUse,
      limit,
    },
    { infinite, fallbackData }
  );
  const previousFiltersRef = useRef(filters);

  useEffect(() => {
    previousFiltersRef.current = filters;
  }, [filters]);

  const hasFiltersChanged = isEqual(filters, previousFiltersRef.current);

  // Scrolling back to the top of the product list when the filters get changed
  useEffect(() => {
    if (gridRef.current) {
      let offsetTop = 0;
      let element: HTMLDivElement = gridRef.current;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent as HTMLDivElement;
      }

      if (isMobile) {
        offsetTop -= MOBILE_SCROLL_OFFSET;
      } else {
        offsetTop -= SCROLL_OFFSET;
      }

      if (window.scrollY < offsetTop) {
        return;
      }

      window.scrollTo({
        top: offsetTop,
      });
    }
  }, [isMobile, hasFiltersChanged, page, gridRef]);

  return useMemo(
    () => ({
      ...productsRes,
      filters,
      page,
      status: getStatus(productsRes),
    }),
    [productsRes, filters, page]
  );
};
