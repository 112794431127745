import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import {
  FilterKey,
  FiltersResponse,
  parseFiltersResponse,
} from '../../../core';
import { FiltersState, useFiltersResponse } from '../../../filters-management';
import { FilterData, TestProps } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MultiSelectFilter } from '../atoms/MultiSelectFilter';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type Props = TestProps & {
  title: string;
  filterKey: FilterKey;
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: FilterOptionClickFunction;
  expanded?: boolean;
};

export const ConfigFilter = ({
  title,
  filterKey,
  selectedFilters,
  availableFilters,
  onOptionClick,
  testId,
  expanded,
}: Props) => {
  const i18n = useIntl();
  const filtersResponse = useFiltersResponse();

  const parsedFiltersResponse = parseFiltersResponse(
    filterKey,
    filtersResponse,
    i18n
  );

  const trackFilterOpen = useTrackFilterCategoryClicked(filterKey);

  const selectedValues = selectedFilters[filterKey];

  const data = useMemo<FilterData>(
    () => ({
      filterKey,
      selectedValues,
      availableFilterValues: parsedFiltersResponse.map((configItem) => ({
        value: Number(configItem?.value) || configItem,
        label: configItem?.label ?? configItem,
        selected: selectedValues?.includes(
          Number(configItem?.value) || configItem
        ),
        disabled: !availableFilters[filterKey].includes(
          Number(configItem?.value) || configItem
        ),
      })),
    }),
    [filtersResponse[filterKey], availableFilters[filterKey], selectedValues]
  );

  return (
    <MultiSelectFilter
      title={title}
      data={data}
      onOptionClick={onOptionClick}
      onOpen={trackFilterOpen}
      testId={testId}
      expanded={expanded}
    />
  );
};
