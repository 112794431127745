import { Deal, useDeals, useGetLoyaltyInfo } from '@finn/platform-modules';
import { useTrackingStore } from '@finn/ua-tracking';
import { useSession } from '@finn/ui-utils';
import { useEffect, useMemo } from 'react';

const getTrackingData = (subscription: Deal) => {
  if (!subscription) return undefined;

  return {
    current_status: subscription.state,
    signed_at: subscription.signing_date,
    contract_start_date: subscription.handover_appointment_date,
    contract_end_date:
      subscription?.preferred_return_date ||
      subscription?.return_date ||
      subscription?.end_date,
    deal_id: subscription.id,
    car_brand: subscription.car?.oem,
    car_model: subscription.car?.model,
  };
};

export const useLoyaltyAndSubscriptionTracker = (
  location = 'Product Listing'
) => {
  const [session] = useSession();
  const contactId = session?.user?.hs_contact_id;
  const isVerified = !!session?.user.email_verified;
  const shouldFetch = !!contactId && isVerified;
  const identify = useTrackingStore((state) => state.identify);
  const { data: loyaltyData, isLoading: isLoadingLoyalty } =
    useGetLoyaltyInfo(contactId);
  const { deals: allSubs, dealsStatus } = useDeals({
    shouldFetch,
  });

  if (allSubs && allSubs?.length) {
    allSubs.sort((a, b) => {
      if (!a?.signing_date || !b?.signing_date) {
        return 0;
      }

      return a?.signing_date > b?.signing_date ? -1 : 1;
    });
  }

  const createdSubs = useMemo(
    () => allSubs?.filter((sub) => sub?.state === 'CREATED') || [],
    [allSubs]
  );

  const activeSubs = useMemo(
    () => allSubs?.filter((sub) => sub?.state === 'ACTIVE') || [],
    [allSubs]
  );

  const endedSubs = useMemo(
    () =>
      allSubs?.filter(
        (sub) => sub?.state === 'ENDED' || sub?.state === 'INACTIVE'
      ) || [],
    [allSubs]
  );

  const isLoading = isLoadingLoyalty || dealsStatus === 'loading';

  useEffect(() => {
    // we want to avoid calling it if both loyaltyData and allSubs are missing or loading
    if (isLoading || !contactId || !(loyaltyData || allSubs)) {
      return;
    }
    identify(
      contactId,
      {
        ...loyaltyData,
        total_created_subscriptions: createdSubs?.length,
        total_active_subscriptions: activeSubs?.length,
        total_ended_subscriptions: endedSubs?.length,
        latest_create_subscription: getTrackingData(createdSubs?.[0]),
        latest_active_subscription: getTrackingData(activeSubs?.[0]),
        latest_ended_subscription: getTrackingData(endedSubs?.[0]),
      },
      location,
      true
    );
  }, [
    isLoading,
    contactId,
    location,
    loyaltyData,
    allSubs,
    identify,
    createdSubs,
    activeSubs,
    endedSubs,
  ]);
};
