import {
  Deal,
  getFormattedDate,
  parseSubscriptionEndDate,
} from '@finn/platform-modules';
import { SERVER_DATE_FORMAT } from '@finn/ua-constants';
import { addBusinessDays } from '@finn/ui-utils';

export const getLastDateOfWeekOfSubscriptionEnd = (
  subscription: Deal | null
) => {
  const { parsedEndDate, hasEndDate } = parseSubscriptionEndDate(subscription);

  return hasEndDate
    ? getFormattedDate(
        addBusinessDays(parsedEndDate.startOf('week'), 4),
        SERVER_DATE_FORMAT
      )
    : '';
};
