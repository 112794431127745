import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { Brand, parseFilterValues } from '../../core';
import { useFilterValues } from '../data/lib';
import { useAvailableFilters } from './useAvailableFilters';
import { useAvailableFiltersForState } from './useAvailableFiltersForState';
import { useImplicitFilters } from './useImplicitFilters';

export const useIsPlpPage = () => {
  const pathname = usePathname();

  return pathname?.startsWith('/subscribe');
};

export const useIsProductGridFiltered = () => {
  const filterValues = useFilterValues();

  const isProductGrid = !useIsPlpPage();

  return isProductGrid && Object.keys(filterValues).length > 0;
};

export const useOriginalProductGridFilters = () => {
  const implicitFilters = useImplicitFilters();

  const filters = useMemo(
    () => parseFilterValues(implicitFilters),
    [implicitFilters]
  );

  return useAvailableFilters({
    filters,
  });
};

export const useProductGridBrands = ({ getAllBrandData }) => {
  const implicitFilters = useImplicitFilters();

  const originalProductGridFilters =
    useAvailableFiltersForState(implicitFilters);

  return originalProductGridFilters.brands
    .filter((brand) => brand.available)
    .map((brand) => (getAllBrandData ? brand : brand.id));
};

export const useProductGridModels = (): string[] => {
  const productGridBrandsData = useProductGridBrands({ getAllBrandData: true });

  const productGridModels = useMemo(() => {
    const models = [];
    (productGridBrandsData as Brand[]).forEach((productGridBrand) => {
      productGridBrand.models.forEach((model) => {
        if (model.available) {
          models.push(model.id);
        }
      });
    });

    return models;
  }, [productGridBrandsData]);

  return productGridModels;
};
