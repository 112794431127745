import { ReactElement, ReactNode, useCallback } from 'react';

import { FilterKey } from '../../../core';
import { TestProps } from '../../helpers';
import { trackFilterOptionClicked } from '../../tracking';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { FilterAccordion } from './FilterAccordion';
import { FilterCheckbox } from './FilterCheckbox';

export type FilterDataArray = {
  filterKey: FilterKey[];
  availableFilterValues: string[];
};

export const getSelectedCount = (selectedValues: unknown[]) => {
  const length = selectedValues.filter((value) => value).length;
  const selectedCount = length > 0 && length;

  return selectedCount;
};

type OptionsProps = {
  selectedValues: boolean[];
  data: FilterDataArray;
  onClick: FilterOptionClickFunction;
};

export const FilterOptions = ({
  selectedValues,
  data,
  onClick,
}: OptionsProps) => {
  const { filterKey, availableFilterValues } = data;

  const handleToggle = useCallback(
    (value: boolean | null, index: number) => {
      onClick({ [filterKey[index]]: value });

      if (value) {
        trackFilterOptionClicked(filterKey[index], value);
      }
    },
    [filterKey, onClick]
  );

  return (
    <div className="w-full">
      {availableFilterValues.map((filterResponseData, index: number) => {
        const value = selectedValues[index];

        return (
          <FilterCheckbox
            key={index}
            label={filterResponseData}
            selected={Boolean(value)}
            onClick={() => handleToggle(value ? null : true, index)}
          />
        );
      })}
    </div>
  );
};

type Props = TestProps & {
  title: string | ReactElement;
  selectedValues: boolean[];
  data: FilterDataArray;
  onOptionClick: FilterOptionClickFunction;
  onOpen?(isOpen: boolean): void;
  children?: ReactNode;
  hideExtras?: boolean;
};

export const MiscFilter = ({
  title,
  selectedValues,
  data,
  onOptionClick,
  onOpen,
  testId,
  children,
  hideExtras,
}: Props) => {
  const selectedCount = getSelectedCount(selectedValues);

  return (
    <FilterAccordion
      title={title}
      count={selectedCount}
      onOpen={onOpen}
      testId={testId}
    >
      <div className="flex w-full flex-col">
        {hideExtras ? null : (
          <FilterOptions
            selectedValues={selectedValues}
            data={data}
            onClick={onOptionClick}
          />
        )}

        {children}
      </div>
    </FilterAccordion>
  );
};
