import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { Skeleton } from '@finn/design-system/atoms/skeleton';
import { showToast } from '@finn/design-system/atoms/toast';
import { ProlongationTerm } from '@finn/platform-modules';
import { useCurrentLocale, useIntl } from '@finn/ui-utils';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useProlongationData } from './hooks/useProlongationData';

type Props = {
  open: boolean;
  onClose: () => void;
  subscriptionId?: string;
  location?: string;
};

type ProlongationResponse = {
  deal: {
    id: string;
  };
  checkoutStep: string;
  contact: {
    id: string;
  };
  hash: string;
};

export const ProlongationModal = ({ open, onClose, subscriptionId }: Props) => {
  const { locale } = useCurrentLocale();
  const [submitting, setSubmitting] = useState(false);
  const i18n = useIntl();
  const prolongationData = useProlongationData(subscriptionId);
  const [selectedTerm, setSelectedTerm] = useState<number>(0);
  const prolongationOptions = useMemo(
    () =>
      [...(prolongationData?.data?.prolongation_options || [])].sort(
        (a, b) => Number(b?.term) - Number(a?.term)
      ),
    [prolongationData]
  );
  const isLoading = prolongationData?.isLoading;
  const isDownpayment = prolongationData?.data?.is_downpayment_subscription;

  useEffect(() => {
    const firstTerm = prolongationOptions[0]?.term;
    if (!selectedTerm && firstTerm) {
      setSelectedTerm(firstTerm);
    }
  }, [selectedTerm, prolongationOptions]);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      const response = await axios.post<ProlongationResponse>(
        '/api/checkout/prolongation/create',
        {
          term: Number(selectedTerm),
          dealId: subscriptionId,
        },
        {
          validateStatus: (status) => status === 200,
        }
      );
      // switching to UA site so router.push will first generate an error as it's external link
      // using location.href to avoid this
      window.location.href = `/${locale}/checkout/prolongation/${response.data.contact.id}/${response.data.deal.id}/${response.data.hash}`;
    } catch (error) {
      showToast({
        status: 'error',
        description: i18n.formatMessage(
          'userAccount.serverErrors.generalError'
        ),
      });
    } finally {
      setTimeout(() => {
        setSubmitting(false);
        // little delay for successful redirect to finish
      }, 2000);
    }
  }, [i18n, locale, selectedTerm, subscriptionId]);

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {i18n.formatMessage('mycars.prolongationModal.title')}
          </ModalTitle>
        </ModalHeader>
        <p className="body-16-regular">
          {i18n.formatMessage('mycars.prolongationModal.description')}
        </p>
        {isDownpayment && (
          <p className="body-16-regular">
            {i18n.formatMessage('mycars.prolongationModal.downpaymentNote')}
          </p>
        )}
        <div className="body-14-semibold">
          {i18n.formatMessage('pdp.contractTerm')}
        </div>
        {isLoading ? (
          <Skeleton className="bg-pearl h-[60px] w-full rounded-sm" />
        ) : (
          <div className="flex flex-col gap-4">
            {prolongationOptions.map((option) => (
              <ProlongationTerm
                key={option?.term}
                selectedTerm={selectedTerm}
                currentTerm={option?.term}
                originalPrice={option?.original_price}
                price={option?.price}
                setSelectedTerm={setSelectedTerm}
                isDownpayment={isDownpayment}
              />
            ))}
          </div>
        )}
        <ModalFooter>
          <Button
            onClick={onSubmit}
            loading={submitting}
            disabled={submitting || isLoading}
          >
            {i18n.formatMessage('checkout.buttonTitle')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
