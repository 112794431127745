import { Deal, parseSubscriptionEndDate } from '@finn/platform-modules';
import { SERVER_DATE_FORMAT } from '@finn/ua-constants';
import axios from 'axios';

export const recommendationFetcher = async (
  lastOpenedSubscription: Deal | null
) => {
  const { parsedEndDate, hasEndDate } = parseSubscriptionEndDate(
    lastOpenedSubscription
  );

  const returnDate = hasEndDate ? parsedEndDate.format(SERVER_DATE_FORMAT) : '';

  try {
    const response = await axios.post('/api/getRecommendation', {
      id: lastOpenedSubscription?.id,
      carId: lastOpenedSubscription?.car?.external_product_id,
      returnDate,
    });

    return response.data;
  } catch (error) {
    console.error(error);

    return null;
  }
};
