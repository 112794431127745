import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import { FilterKey, FiltersResponse } from '../../../core';
import { FiltersState } from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { FilterAccordion } from '../atoms/FilterAccordion';
import { RangeSelector } from '../atoms/RangeSelector';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type Props = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onClick: FilterOptionClickFunction;
};

export const PowerFilter = ({
  selectedFilters,
  availableFilters,
  onClick,
}: Props) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked('power');
  const selectedRange: [number, number] = useMemo(
    () => [selectedFilters.min_power, selectedFilters.max_power],
    [selectedFilters.min_power, selectedFilters.max_power]
  );

  const isPowerSelected = useMemo(() => {
    return selectedRange.some((item) => item !== undefined);
  }, [selectedRange]);

  return (
    <FilterAccordion
      title={i18n.formatMessage('plp.power')}
      count={isPowerSelected && 1}
      onOpen={trackFilterOpen}
      testId={FilterTestID.PowerFilter}
    >
      <RangeSelector
        suffix=" kW"
        selectedRange={selectedRange}
        availableRange={[
          availableFilters.min_power,
          availableFilters.max_power,
        ]}
        isRangeSelected={isPowerSelected}
        onRangeSelection={onClick}
        minRangeKey={FilterKey.MIN_POWER}
        maxRangeKey={FilterKey.MAX_POWER}
        minRangeLabel={i18n.formatMessage('plp.minPower')}
        maxRangeLabel={i18n.formatMessage('plp.maxPower')}
      />
    </FilterAccordion>
  );
};
