import { Button } from '@finn/design-system/atoms/button';
import { useIntl } from '@finn/ui-utils';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterKey } from '../../../core';
import { AvailabilityMap, useFilterValues } from '../../../filters-management';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { getAvailabilityMap, WeekPickerNext } from './WeekPickerNext';

type Props = {
  selectedDates: [string | undefined, string | undefined];
  availableDates: string[];
  isAvailabilitySelected: boolean;
  onDateSelection?: FilterOptionClickFunction;
};

export const AvailabilitySelector = ({
  selectedDates,
  availableDates,
  isAvailabilitySelected,
  onDateSelection,
}: Props) => {
  const i18n = useIntl();

  const filterValues = useFilterValues();

  const [startDate, setStartDate] = useState<Date | null>(
    filterValues.available_from ? new Date(filterValues.available_from) : null
  );

  const availabilityMap = useMemo<AvailabilityMap>(
    () => getAvailabilityMap(availableDates),
    [availableDates]
  );

  const getAvailableDates = () => {
    const parsedStartDate = dayjs(startDate);
    const formattedDate = parsedStartDate.format('YYYY-MM-DD');
    let availableDate = availabilityMap[formattedDate];

    // Allow dates for coming_soon cars outside of the available dates.
    if (startDate && !availableDate) {
      availableDate = {
        startDate: parsedStartDate.format('YYYY-MM-DD'),
        endDate: parsedStartDate.add(5, 'd').format('YYYY-MM-DD'),
      };
    }

    const availableStartDate = availableDate?.startDate;
    const availableEndDate = availableDate?.endDate;

    return [availableStartDate, availableEndDate];
  };

  useEffect(() => {
    const [availableStartDate, availableEndDate] = getAvailableDates();

    if (availableStartDate && availableEndDate) {
      onDateSelection?.({
        [FilterKey.AVAILABLE_FROM]: availableStartDate,
        [FilterKey.AVAILABLE_TO]: availableEndDate,
      });
    }
  }, [startDate]);

  const handleChange = (dateValue: Date) => {
    setStartDate(dateValue);
  };

  const handleResetForm = useCallback(() => {
    onDateSelection?.({
      [FilterKey.AVAILABLE_FROM]: undefined,
      [FilterKey.AVAILABLE_TO]: undefined,
    });
  }, [onDateSelection]);

  return (
    <div className="-mx-6 flex w-[calc(100%+32px)] flex-col gap-4">
      <WeekPickerNext
        availability={availableDates}
        startDate={selectedDates?.[0] && new Date(selectedDates?.[0])}
        onChange={handleChange}
      />

      <Button
        variant="action"
        className="w-full"
        size="md"
        onClick={handleResetForm}
        disabled={!isAvailabilitySelected}
      >
        {i18n.formatMessage('plp.reset')}
      </Button>
    </div>
  );
};
