import { usePrevious } from '@finn/auto-ui/hooks/usePrevious';
import {
  defaultFilterValues,
  useFiltersResponse,
  useFilterValues,
} from '@finn/ua-vehicle';

import { useTrackingEffect } from '~/hooks/useTrackingEffect';

type FiltersTrackingProps = {
  total: number;
};

export const FiltersTracking = ({ total }: FiltersTrackingProps) => {
  const filtersResponse = useFiltersResponse();
  const trackedFilterValues = useFilterValues();
  const previousFilterValues = usePrevious(
    trackedFilterValues,
    defaultFilterValues
  );

  useTrackingEffect(
    total,
    true,
    filtersResponse,
    trackedFilterValues,
    previousFilterValues
  );

  return null;
};
