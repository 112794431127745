import { Spinner } from '@finn/design-system/atoms/spinner';

import { BottomMarker } from './BottomMarket';

type Props = {
  isLoading: boolean;
  loadNextPage(): void;
};

export const ProductListInfiniteScrollTrigger = ({
  isLoading,
  loadNextPage,
}: Props) => {
  return (
    <>
      {isLoading && (
        <div className="w-full">
          <div className="text-center">
            <Spinner className="fill-trustedBlue h-12 w-12" />
          </div>
        </div>
      )}

      {!isLoading && <BottomMarker onReached={loadNextPage} />}
    </>
  );
};
