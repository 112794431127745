import { cn, getFormattedPrice, useIntl } from '@finn/ui-utils';

import { useForBusinessCookie } from '../../helpers';
import { ProductImage } from './components/ProductImage';
import { ProductLink } from './components/ProductLink';
import { ModelCardWithType } from './types';

type Props = {
  modelInfo: ModelCardWithType;
  onModelCardSelected: () => void;
  urlParams?: string;
  isForIframe?: boolean;
  partnerDiscount?: number;
};

export const ModelCard = ({
  modelInfo,
  onModelCardSelected,
  urlParams,
  isForIframe = false,
  partnerDiscount = 0,
}: Props) => {
  const i18n = useIntl();
  const forBusinessCookie = useForBusinessCookie();

  const { brand, model, imageURL, configsCount, displayPrice, vehicle } =
    modelInfo;
  const brandModelLabel = `${brand} ${model}`;
  const perMonthLabel = i18n.formatMessage(
    !forBusinessCookie ? 'general.perMonth' : 'general.perMonthWithoutVat'
  );

  const priceTotal = displayPrice - displayPrice * partnerDiscount;
  const priceFormatted = getFormattedPrice(i18n, priceTotal, 0);
  const preDiscountPrice =
    partnerDiscount > 0 ? getFormattedPrice(i18n, displayPrice, 0) : null;

  const CardContent = (
    <>
      <div className="bg-snow relative rounded">
        <ProductImage vehiclePictureURL={imageURL} altText={brandModelLabel} />
      </div>

      <div className="mt-3 grid gap-3">
        <h5
          className={cn(
            'body-14-semibold sm:body-16-semibold overflow-hidden text-ellipsis py-1 sm:text-nowrap'
          )}
        >
          {brandModelLabel}
        </h5>

        {/* Configs amount section */}
        <span className="py-0.5">
          <p className="body-12-light sm:body-14-light">
            {configsCount}{' '}
            {configsCount > 1
              ? i18n.formatMessage('plp.cars')
              : i18n.formatMessage('plp.car')}
          </p>
        </span>

        {/* Price section */}
        <div className="body-14-semibold">
          <div className="row-y-2 mb-1 flex flex-wrap items-center md:mb-1.5">
            {i18n.formatMessage('plp.priceFrom')}&nbsp;
            {preDiscountPrice && (
              <div className="text-iron mr-1 line-through">
                {preDiscountPrice}
              </div>
            )}
            {priceFormatted}&nbsp;
            <div className="body-14-light">{perMonthLabel}</div>
          </div>
        </div>
      </div>
    </>
  );

  if (configsCount === 1) {
    return (
      <ProductLink
        vehicle={vehicle}
        urlParams={urlParams}
        isForIframe={isForIframe}
      >
        {CardContent}
      </ProductLink>
    );
  }

  return (
    <div
      onClick={onModelCardSelected}
      className="relative flex h-full w-full flex-col overflow-hidden hover:cursor-pointer"
    >
      {CardContent}
    </div>
  );
};
