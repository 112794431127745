import { useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import {
  FilterKey,
  FiltersResponse,
  parseFiltersResponse,
} from '../../../core';
import { FiltersState, useFiltersResponse } from '../../../filters-management';
import { FilterData, FilterToggleData, TestProps } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MultiSelectFilter } from '../atoms/MultiSelectFilter';

type Props = TestProps & {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: (filterObject: FiltersState) => void;
};

export const FeaturesFilter = ({
  selectedFilters,
  onOptionClick,
  availableFilters,
  testId,
}: Props) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked(FilterKey.FEATURES);
  const filtersResponse = useFiltersResponse();

  const parsedFiltersResponse = useMemo(
    () => parseFiltersResponse(FilterKey.FEATURES, filtersResponse, i18n),
    [filtersResponse, i18n]
  );

  const selectedValues = selectedFilters[FilterKey.FEATURES];

  const availableFilterValues: FilterToggleData[] = useMemo(
    () =>
      parsedFiltersResponse
        .map((configItem) => ({
          value: configItem.value,
          label: configItem.label,
          selected: selectedValues?.includes(configItem?.value),
          disabled: !availableFilters[FilterKey.FEATURES].includes(
            configItem.value
          ),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [
      availableFilters[FilterKey.FEATURES],
      parsedFiltersResponse,
      selectedValues,
    ]
  );
  const data = useMemo<FilterData>(() => {
    return {
      filterKey: FilterKey.FEATURES,
      selectedValues,
      availableFilterValues,
    };
  }, [availableFilterValues, selectedValues]);

  if (!parsedFiltersResponse.length) {
    return null;
  }

  return (
    <MultiSelectFilter
      title={i18n.formatMessage('plp.features.title')}
      data={data}
      onOptionClick={onOptionClick}
      onOpen={trackFilterOpen}
      testId={testId}
    />
  );
};
