import { Checkbox } from '@finn/design-system/atoms/checkbox';
import { ReactNode, useCallback, useEffect, useState } from 'react';

type Props = {
  label: string;
  selected: boolean;
  disabled?: boolean;
  onClick(): void;
  children?: ReactNode;
};

export const FilterCheckbox = ({
  label,
  onClick,
  selected,
  disabled,
  children,
}: Props) => {
  const [checked, setChecked] = useState(selected);

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  const handleChange = useCallback(
    (value) => {
      setChecked(value);
      onClick();
    },
    [setChecked, onClick]
  );

  return (
    <label
      htmlFor={`filter-checkbox-${label}`}
      className="my-3 flex min-h-6 w-full cursor-pointer items-center justify-between"
    >
      <Checkbox
        id={`filter-checkbox-${label}`}
        checked={checked}
        disabled={disabled}
        label={label}
        onCheckedChange={handleChange}
      />
      {/* wrapping children with label so that when hovering/clicking on children will check Checkbox as well */}
      <label
        htmlFor={`filter-checkbox-${label}`}
        className="flex cursor-pointer items-center"
      >
        {children}
      </label>
    </label>
  );
};
