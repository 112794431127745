import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { useIntl } from '@finn/ui-utils';
import Link from 'next/link';
import { useCallback } from 'react';

export const BusinessBlurb = () => {
  const i18n = useIntl();

  const track = useTrackingStore((state) => state.track);

  const onB2BLinkClick = useCallback(() => {
    track(TrackingEventName.LINK_CLICKED, { location: 'B2B' });
  }, [track]);

  return (
    <div className="bg-snow my-4 flex flex-col gap-3 p-4">
      <div className="body-12-light">
        {i18n.formatMessage('plp.businessFilter.blurbQuestion')}
      </div>
      <div className="body-12-semibold underline" onClick={onB2BLinkClick}>
        <Link href="/b2b">
          {i18n.formatMessage('plp.businessFilter.blurbLinkText')}
        </Link>
      </div>
    </div>
  );
};
