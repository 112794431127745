import { SaleBadgeFilled } from '@finn/design-system/icons/sale-badge-filled';
import { useCurrentLocale, useIntl } from '@finn/ui-utils';
import { useMemo } from 'react';

import { FilterKey } from '../../../core';
import { FiltersState } from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MiscFilter } from '../atoms/MiscFilter';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type DealsFilterProps = {
  selectedFilters: FiltersState;
  onOptionClick: FilterOptionClickFunction;
};

type FilterData = {
  filterKey: FilterKey[];
  availableFilterValues: string[];
};

export const DealsFilter = ({
  selectedFilters,
  onOptionClick,
}: DealsFilterProps) => {
  const i18n = useIntl();
  const { isUSA } = useCurrentLocale();
  const trackFilterOpen = useTrackFilterCategoryClicked('deals');

  const filterData: FilterData = useMemo(() => {
    const allKeysAndValues = [
      {
        key: FilterKey.HAS_DEALS,
        value: i18n.formatMessage('plp.discounts'),
      },
    ];

    // TODO: Add product groups in the future

    return {
      filterKey: allKeysAndValues.map((item) => item.key),
      availableFilterValues: allKeysAndValues.map((item) => item.value),
    };
  }, [i18n]);

  const selectedDeals = [selectedFilters.has_deals];

  // TODO: enable for the US when there are cars in stock
  if (isUSA) return;

  return (
    <MiscFilter
      data={filterData}
      onOpen={trackFilterOpen}
      onOptionClick={onOptionClick}
      selectedValues={selectedDeals}
      title={
        <div className="flex items-center">
          <SaleBadgeFilled className="fill-trustedBlue mr-2 h-4 w-4" />
          {i18n.formatMessage('plp.deals')}
        </div>
      }
      testId={FilterTestID.DealsFilter}
    />
  );
};
