import 'react-indiana-drag-scroll/dist/style.css';

import { useIntl } from '@finn/ui-utils';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { ScrollContainer } from 'react-indiana-drag-scroll';

import { setForBusinessCookie } from '../../../helpers';
import { DATE_IN_14_DAYS, DATE_IN_30_DAYS, FilterKey } from '../../core';
import {
  useFiltersResponse,
  useFilterValues,
  useMergeFilterValues,
} from '../../filters-management';
import { trackFilterTagRemoved, trackFilterTagsScrolled } from '../../tracking';
import { useFormatPrice } from '../hooks/useFormatPrice';
import {
  FILTER_TAG_LABEL_IDS,
  FilterTag,
  SUPPORTED_FILTER_KEYS,
} from './atoms/FilterTag';

function getDateLabel(dateStr: string): string {
  const dayjsDate = dayjs(dateStr);

  const startMonthStr = dayjsDate.format('MMM');
  const startDateStr = dayjsDate.format('D');

  return `${startMonthStr} ${startDateStr}`;
}

function useGetFilterTagValue() {
  const formatPrice = useFormatPrice();
  const i18n = useIntl();

  return useCallback(
    (filterKey: FilterKey, value: unknown) => {
      switch (filterKey) {
        case FilterKey.MIN_PRICE:
        case FilterKey.MAX_PRICE:
          return formatPrice(value as number);

        case FilterKey.MIN_POWER:
          return `${value} kW`;
        case FilterKey.MAX_POWER:
          return `${value} kW`;

        case FilterKey.MIN_PRICE_MSRP:
          return (value as string[])
            .map((val) => formatPrice(val as unknown as number), 0)
            .join(' - ');

        case FilterKey.AVAILABLE_TO:
          const dateValues = value as string[];
          if (dateValues?.length === 1 && dateValues[0] === DATE_IN_14_DAYS) {
            return i18n.formatMessage(`plp.availabilityFilter.14days`);
          }
          if (dateValues?.length === 1 && dateValues[0] === DATE_IN_30_DAYS) {
            return i18n.formatMessage(`plp.availabilityFilter.30days`);
          }

          const availabilityLabel = i18n.formatMessage('plp.availability');
          const dates = dateValues.map(getDateLabel).join(' - ');

          return `${availabilityLabel}: ${dates}`;

        case FilterKey.TERMS:
          return `${value as string} Mo.`;

        case FilterKey.FEATURES:
          return i18n.formatMessage(`plp.features.filters.${value}`);

        case FilterKey.IS_FOR_BUSINESS:
        case FilterKey.IS_YOUNG_DRIVER:
        case FilterKey.HAS_DEALS:
        case FilterKey.HAS_HITCH:
          return undefined;

        default:
          return value;
      }
    },
    [formatPrice]
  );
}

function useGetFilterTagLabel() {
  const i18n = useIntl();

  return useCallback(
    (filterKey: FilterKey) => {
      switch (filterKey) {
        case FilterKey.MIN_PRICE:
        case FilterKey.MAX_PRICE:
        case FilterKey.MIN_PRICE_MSRP:
        case FilterKey.MIN_POWER:
        case FilterKey.MAX_POWER:
        case FilterKey.IS_YOUNG_DRIVER:
        case FilterKey.IS_FOR_BUSINESS:
        case FilterKey.HAS_DEALS:
        case FilterKey.HAS_HITCH:
          return i18n.formatMessage(FILTER_TAG_LABEL_IDS[filterKey]);
      }
    },
    [i18n]
  );
}

const SCROLL_TRACKING_DEBOUNCE_MS = 1000;

export const FilterTagList = () => {
  const filterValues = useFilterValues();
  const filtersResponse = useFiltersResponse();
  const mergeFilterValues = useMergeFilterValues();
  const getFilterTagLabel = useGetFilterTagLabel();
  const getFilterTagValue = useGetFilterTagValue();

  const selectedFilterKeys = useMemo(
    () =>
      SUPPORTED_FILTER_KEYS.filter((filterKey) => filterKey in filterValues),
    [filterValues]
  );

  const filterTagDataList = useMemo(() => {
    const dataList: { filterKey: FilterKey; filterValue: unknown }[] = [];

    for (const filterKey of selectedFilterKeys) {
      const filterValue = filterValues[filterKey];

      if (filterKey === FilterKey.AVAILABLE_TO) {
        const dates = [];

        if (filterValues[FilterKey.AVAILABLE_FROM]) {
          dates.push(filterValues[FilterKey.AVAILABLE_FROM]);
        }
        dates.push(filterValues[FilterKey.AVAILABLE_TO]);

        dataList.push({
          filterKey,
          filterValue: dates,
        });
      } else if (
        filterKey === FilterKey.MIN_PRICE_MSRP ||
        filterKey === FilterKey.MAX_PRICE_MSRP
      ) {
        const minVal =
          filterValues[FilterKey.MIN_PRICE_MSRP] ||
          filtersResponse[FilterKey.MIN_PRICE_MSRP];

        const maxVal =
          filterValues[FilterKey.MAX_PRICE_MSRP] ||
          filtersResponse[FilterKey.MAX_PRICE_MSRP];

        const existingItem = dataList.find(
          (item) => item.filterKey === FilterKey.MIN_PRICE_MSRP
        );
        if (existingItem) {
          existingItem.filterValue = [minVal, maxVal];
        } else {
          dataList.push({
            filterKey: FilterKey.MIN_PRICE_MSRP,
            filterValue: [Math.round(minVal), Math.round(maxVal)],
          });
        }
      } else if (Array.isArray(filterValue)) {
        (filterValue as unknown[]).forEach((value: unknown) =>
          dataList.push({ filterKey, filterValue: value })
        );
      } else {
        dataList.push({ filterKey, filterValue });
      }
    }

    return dataList;
  }, [filterValues, selectedFilterKeys]);

  const handleRemove = useCallback(
    (filterKey: FilterKey, removedFilterValue: unknown) => {
      const filterValue = filterValues[filterKey];

      if (
        filterKey === FilterKey.MIN_PRICE_MSRP ||
        filterKey === FilterKey.MAX_PRICE_MSRP
      ) {
        mergeFilterValues({
          [FilterKey.MIN_PRICE_MSRP]: undefined,
          [FilterKey.MAX_PRICE_MSRP]: undefined,
        });
      }
      if (filterKey === FilterKey.MIN_POWER) {
        mergeFilterValues({
          [FilterKey.MIN_POWER]: undefined,
        });
      } else if (filterKey === FilterKey.MAX_POWER) {
        mergeFilterValues({
          [FilterKey.MAX_POWER]: undefined,
        });
      } else if (filterKey === FilterKey.AVAILABLE_TO) {
        mergeFilterValues({
          [FilterKey.AVAILABLE_FROM]: undefined,
          [FilterKey.AVAILABLE_TO]: undefined,
        });
      } else if (Array.isArray(filterValue)) {
        const updatedFilters = {
          [filterKey]: (filterValue as unknown[]).filter(
            (value) => value !== removedFilterValue
          ),
        };

        if (filterKey === FilterKey.BRANDS) {
          const brandModels = filtersResponse?.brands
            ?.find((brand) => brand.id === removedFilterValue)
            ?.models.map((model) => model.id);

          const modelsToKeep = filterValues[FilterKey.MODELS]?.filter(
            (selectedModel) => !brandModels?.includes(selectedModel)
          );

          updatedFilters[FilterKey.MODELS] = modelsToKeep as unknown[];
        }

        mergeFilterValues(updatedFilters);
      } else {
        if (filterKey === FilterKey.IS_FOR_BUSINESS) {
          setForBusinessCookie(false);
        }

        mergeFilterValues({
          [filterKey]: undefined,
        });
      }

      trackFilterTagRemoved();
    },
    [filterValues, mergeFilterValues]
  );

  const handleTrackScroll = useMemo(
    () =>
      debounce(() => {
        trackFilterTagsScrolled();
      }, SCROLL_TRACKING_DEBOUNCE_MS),
    []
  );

  return (
    <div className="relative w-full">
      <ScrollContainer hideScrollbars onScroll={handleTrackScroll}>
        <div
          className="flex cursor-grab items-center gap-2 active:cursor-grabbing"
          role="list"
        >
          {filterTagDataList.map(({ filterKey, filterValue }) => (
            <FilterTag
              key={`${filterKey}-${filterValue}`}
              label={getFilterTagLabel(filterKey)}
              value={getFilterTagValue(filterKey, filterValue)}
              onRemove={() => handleRemove(filterKey, filterValue)}
            />
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
};
