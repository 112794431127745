import { Button } from '@finn/design-system/atoms/button';
import { Close } from '@finn/design-system/icons/close';
import { useMemo } from 'react';

import { FilterKey } from '../../../core';
import { FilterTestID } from '../../helpers';

type Props = {
  label?: string;
  value?: unknown;
  onRemove(): void;
};

export const SUPPORTED_FILTER_KEYS = [
  FilterKey.BRANDS,
  FilterKey.MODELS,
  FilterKey.MIN_PRICE,
  FilterKey.MAX_PRICE,
  FilterKey.MIN_PRICE_MSRP,
  FilterKey.MAX_PRICE_MSRP,
  FilterKey.MIN_POWER,
  FilterKey.MAX_POWER,
  FilterKey.AVAILABLE_TO,
  FilterKey.TERMS,
  FilterKey.CAR_TYPES,
  FilterKey.GEARSHIFTS,
  FilterKey.HAS_DEALS,
  FilterKey.FUELS,
  FilterKey.HAS_HITCH,
  FilterKey.FEATURES,
  FilterKey.IS_YOUNG_DRIVER,
  FilterKey.IS_FOR_BUSINESS,
] as const;

export const FILTER_TAG_LABEL_IDS = {
  [FilterKey.BRANDS]: 'plp.brand',
  [FilterKey.HAS_DEALS]: 'plp.discounts',
  [FilterKey.MODELS]: 'plp.model',
  [FilterKey.FEATURES]: 'plp.feature',
  [FilterKey.MIN_PRICE]: 'plp.minPrice',
  [FilterKey.MAX_PRICE]: 'plp.maxPrice',
  [FilterKey.MIN_PRICE_MSRP]: 'plp.msrpPrice',
  [FilterKey.MIN_POWER]: 'plp.minPowerTag',
  [FilterKey.MAX_POWER]: 'plp.maxPowerTag',
  [FilterKey.AVAILABLE_TO]: 'plp.availability',
  [FilterKey.TERMS]: 'plp.terms',
  [FilterKey.CAR_TYPES]: 'plp.cartype',
  [FilterKey.GEARSHIFTS]: 'plp.gearshift',
  [FilterKey.FUELS]: 'plp.fuels',
  [FilterKey.HAS_HITCH]: 'plp.hasHitch',
  [FilterKey.IS_FOR_BUSINESS]: 'plp.forBusiness',
  [FilterKey.IS_YOUNG_DRIVER]: 'plp.isYoungDriverTag',
};

export const FilterTag = ({ label, value, onRemove }: Props) => {
  const text = useMemo(() => {
    if (label && value) {
      return `${label}: ${value}`;
    }

    if (label && !value) {
      return label;
    }

    return value as string;
  }, [label, value]);

  return (
    <div className="bg-snow flex select-none items-stretch whitespace-nowrap">
      <div
        className="flex items-center p-2.5"
        data-testid={`${text}-${FilterTestID.Tag}`}
      >
        <span className="body-12-semibold">{text}</span>
      </div>
      <Button
        type="button"
        variant="ghost"
        className="h-max w-6 cursor-pointer select-none items-center justify-center self-center border-none bg-transparent p-0"
        onClick={onRemove}
      >
        <Close className="!h-3.5 !w-3.5" />
      </Button>
    </div>
  );
};
