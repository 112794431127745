import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';

import { setForBusinessCookie } from '../../../helpers';
import { FilterKey, FiltersResponse, FilterValuesObject } from '../../core';
import { useMergeFilterValues } from '../data/lib';
import { FiltersState } from './useAvailableFiltersForState';
import { useFiltersResponse } from './useFilterResponse';

const getModelsToKeep = (
  previousFilters: FiltersState,
  currentFilters: FiltersState,
  filtersResponse: FiltersResponse
) => {
  const brandBeingRemoved = previousFilters[FilterKey.BRANDS]?.filter(
    (item) => !currentFilters[FilterKey.BRANDS].includes(item)
  )?.[0];

  if (brandBeingRemoved) {
    const brandModels = filtersResponse.brands
      .find((brand) => brand.id === brandBeingRemoved)
      .models.map((model) => model.id);

    if (previousFilters[FilterKey.MODELS]) {
      const modelsToKeep = previousFilters[FilterKey.MODELS]?.filter(
        (selectedModel) => !brandModels.includes(selectedModel)
      );

      return modelsToKeep;
    }
  }

  return previousFilters[FilterKey.MODELS];
};

/**
 * Stages any changes to be applied at filters
 *
 * Applies additional business rules:
 *  - Deselect corresponding models if the parent brand is deselected
 *  - If a filter value is no longer available, remove it from the filter object
 *
 * @param initialValues
 * @returns
 */
export const useFilterState = (
  availableFilters: FiltersResponse,
  initialValues: FilterValuesObject
): [FiltersState, (filtersObject: FiltersState) => FiltersState] => {
  const [filterState, setFilterState] = useState<FiltersState>(initialValues);
  const filtersResponse = useFiltersResponse();
  const hasChanges = useRef(false);
  const mergeFilterValues = useMergeFilterValues();

  const initialValuesRef = useRef(undefined);

  useEffect(() => {
    if (!isEqual(initialValuesRef.current, initialValues)) {
      initialValuesRef.current = initialValues;
      setFilterState(initialValues);

      setForBusinessCookie(initialValues.is_for_business);
    }
  }, [initialValues]);

  const setFilterValues = useCallback(
    (selectedFilters: FiltersState) => {
      hasChanges.current = true;

      if (FilterKey.BRANDS in selectedFilters) {
        selectedFilters[FilterKey.MODELS] = getModelsToKeep(
          filterState,
          selectedFilters,
          filtersResponse
        );
      }

      const newFilters = { ...filterState, ...selectedFilters };
      setFilterState(newFilters);

      setForBusinessCookie(selectedFilters.is_for_business);

      return newFilters;
    },
    [filterState, filtersResponse]
  );

  // This needs to wait for availableFilters to be returned from the server
  useEffect(() => {
    // Only update available filter values after first interaction
    if (!hasChanges.current) {
      return;
    }

    const newValues: FiltersState = { ...filterState };

    if (FilterKey.BRANDS in filterState) {
      newValues[FilterKey.BRANDS] = filterState[FilterKey.BRANDS].filter(
        (value) =>
          availableFilters.brands.find(
            (brand) => brand.id === value && brand.available
          )
      );
    }

    for (const key of [
      FilterKey.MODELS,
      FilterKey.FUELS,
      FilterKey.TERMS,
      FilterKey.GEARSHIFTS,
      FilterKey.CAR_TYPES,
      FilterKey.FEATURES,
    ]) {
      if (key in filterState) {
        newValues[key] = filterState[key].filter((value) =>
          availableFilters[key].includes(value)
        );
      }
    }

    if (JSON.stringify(newValues) === JSON.stringify(filterState)) {
      return;
    }

    mergeFilterValues(newValues);
    hasChanges.current = false;
    setFilterState(newValues);
  }, [filterState, availableFilters, mergeFilterValues]);

  return [filterState, setFilterValues];
};
